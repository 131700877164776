@import "abstracts/_abstracts";
.HomeHero {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 1920px;
    margin: 0 auto;
    border-top: 1px solid var(--colorOutlines);
    border-bottom: 1px solid var(--colorOutlines);

    &-frame {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        align-items: flex-start;
        justify-content: center;
        min-height: 100%;
        padding-left: 8%;
    }

    &-image {
        color: var(--colorBrand);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        clip: rect(0, auto, auto, 0);
        overflow: hidden;

        img, svg, &:after {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        img {
            object-fit: cover;
        }

        svg:last-of-type {
            position: fixed;
        }

        &:after {
            content: ' ';
            background: linear-gradient(9deg, transparent 50%, #00000096 100%);
        }
    }

    &-title {
        color: #fff;
        margin: 0;
        text-shadow: 0 0 11px #000000a6;
        font-weight: 800;
        font-size: 5em;
        line-height: 1.05;
        @include media('<=lg') {
            font-size: var(--textXxxl);
        }
        @include media('<=xs') {
            font-size: var(--textXxl);
        }
    }

    &-perex {
        color: #fff;
        text-shadow: 0 0 7px #000000;
        margin-top: var(--spaceSm);
        font-size: var(--textXl);
        max-width: 33em;
        font-weight: 200;
        @include media('<=md') {
            font-size: var(--textLg);
        }
    }

    &-btn {
        margin-top: var(--spaceLg);
    }
}